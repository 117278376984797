import React, { useState, useRef } from 'react';
//import type { Location, useMatches } from "react-router-dom";
import {
  Outlet,
  ScrollRestoration,
  Location,
  useMatches
} from "react-router-dom";
import Icon_Svsf from "../svg/svg_icon_svsf";
import Sidenav from "../modules/Sidenav_Event";
import Navbar from "../modules/Navbar";
import Footer from "../modules/Footer";
import { MDBContainer } from 'mdb-react-ui-kit';


export const loader = async ({ params }) => {
  const resJson = [];

  return resJson;
};

export default function Root() {
  const [showOnLoggedin, setshowOnLoggedin] = useState("");
  const [prodUrlArray, setProdUrlArray] = useState(['www.svsf-ta.se', 'svsf-ta.se', 'www.skytteta.se', 'skytteta.se', 'localhost']);
  const [hideOnLoggedin, setHideOnLoggedin] = useState("d-none");
  const [loggedin, setLoggedIn] = useState(() => {

    const saved = localStorage.getItem("key_id");
    console.log('window.location.hostname', window.location.hostname);

    if (saved) {
      setshowOnLoggedin("");
      setHideOnLoggedin("d-none");
      return true;
    } else {
      setshowOnLoggedin("d-none");
      setHideOnLoggedin("");
      return false;
    }
  });

  const [basicOpen, setBasicOpen] = useState(true);

  return (
    <>
      <header>
        <Navbar updateSidenav={setBasicOpen} sidenavState={basicOpen} showOnLoggedin={showOnLoggedin} hideOnLoggedin={hideOnLoggedin} />
      </header>
      {prodUrlArray.includes(window.location.hostname)
        ?
        <MDBContainer style={{ minHeight: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <h1>Välkommen till SkytteTa</h1>
          <p>Tävlingsadministrationsportalen för Svenska Skyttesportförbundet</p>
          <Icon_Svsf style={{ width: '100px', height: '100px', margin: '20px 0' }} />
          <p>Här kan du hantera och administrera tävlingar på ett enkelt och smidigt sätt.</p>
          {/* <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', marginTop: '20px' }}>
            <div>
              <h4>Pågående tävlingar</h4>
            </div>
            <div>
              <h4>Kommande tävlingar</h4>
            </div>
            <div>
              <h4>Köpa licens</h4>
            </div>
          </div> */}
        </MDBContainer>
        
        :
        <Outlet />}

      <Footer />
      <ScrollRestoration
        getKey={(location, matches) => {
          // default behavior
          console.log('location', location);
          return location.key;
        }}
      />
    </>
  );
}
