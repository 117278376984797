function AdjustCurrency(amount){

  amount = amount.toString();
  amount = parseFloat(amount).toFixed(2);
  if(amount.includes(".")){
    if(amount.split('.')[1].length == 1){
      amount = amount + '0'
    } 
    amount = amount.split('.').join("");
  }
  else{
    amount = amount + '00';
  }

  return Number(amount);
}

export function CreateOrderBody(obj,key,payee,metadata,user_key){
  
  const paymentBody = {
    "user_id": user_key,
    "payment_key": key,
    "payee_ref": payee.data.payeeInfo.payeeReference,
    "payee_name": payee.data.payeeInfo.payeeName,
    "order_ref": payee.data.payeeInfo.orderReference,
    "status": obj.data.paymentOrder.status,
    "amount": obj.data.paymentOrder.amount,
    "vat_amount": obj.data.paymentOrder.vatAmount,
    "operation": obj.data.paymentOrder.operation,
    "created": obj.data.paymentOrder.created,
    "updated": obj.data.paymentOrder.updated,
    "description": obj.data.paymentOrder.description,
    "metadata": metadata.data.metadata
  };

  return paymentBody;
}

export function CreatePaymentBody(payment,orderID){

  var totalAmount = 0;
  var vatTotalAmount = 0;

  function CreateOrderItems(orders){
    var orderItems = [];
    orders.forEach((element,index) => {

      totalAmount = totalAmount + element.amount;
      vatTotalAmount = vatTotalAmount + element.vatAmount;

      orderItems[index] = { 
        "reference": element.reference,
        "name": element.name,
        "type": element.type,
        "class": element.class,
        "description": element.description,
        "quantity": 1,
        "quantityUnit": "pcs",
        "unitPrice": AdjustCurrency(element.amount),
        "vatPercent": element.vatPercent,
        "amount": AdjustCurrency(element.amount),
        "vatAmount": AdjustCurrency(element.vatAmount)}
    });
    return orderItems;
  
  }
  const orderItems = CreateOrderItems(payment.orders);
  const metadata = payment.metadata;

	const url = () => {
		if(process.env.NODE_ENV == "development"){
				return window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
			} else {
				return window.location.protocol + "//" + window.location.hostname
			}
	}

  const body = {"message_url": "https://api.externalintegration.payex.com/psp/paymentorders",
  "message_body":{
      "paymentorder": {
          "operation": "Purchase",
          "currency": "SEK",
          "amount": AdjustCurrency(totalAmount),
          "vatAmount": AdjustCurrency(vatTotalAmount),
          "description": payment.description,
          "userAgent": window.navigator.userAgent,
          "language": "sv-SE",
          "productName": "Checkout3",
          "implementation": "PaymentsOnly",
          "urls": {
              "hostUrls": [ "https://example.com", "https://example.net" ],
              "paymentUrl": "https://example.com/perform-payment",
              "completeUrl": url() + "/process-payment",
              "cancelUrl": url() + "/payment-error",
              "callbackUrl": "https://api.example.com/payment-callback"
          },
          "orderItems": orderItems,
          "payeeInfo": {
            "payeeId": "b189f36f-4435-4e71-a1aa-692112f8eb46",
            "payeeReference": orderID,
            "payeeName": "Ardis",
            "orderReference": "LIC-" + orderID
          },
          "metadata": metadata
      }
    }
  }

  return body;
}