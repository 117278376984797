export async function AddLicense(user_key, startDate, endDate){
	
	const response = await fetch("https://dev.svsf-ta.se/api/app/user/do_submit/" + user_key, {
	//headers: headers,
	method: 'POST',
	body: JSON.stringify({
    "action": "add_licens",
    "db_licens_id": 4,
		"db_start_date": startDate,
    "db_end_date": endDate,
    "db_expired_date": endDate
})
		

	});
	
	if (!response.ok) {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	}    
	const obj = await response.json();
	return  obj;
}