import React, { useState } from 'react';
import { 
  MDBRow,
  MDBCol,
  MDBAlert,
  MDBDatepicker,
  MDBValidation,
  MDBValidationItem,
  MDBSelect,
  MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog
  } from "mdb-react-ui-kit";
import { BorderCol } from '../../modules/BorderCol';
import { CreatePaymentBody } from '../payment/utils/CreatePaymentBody';
import { new_order, update_order } from '../payment/utils/PaymentRequest';
import {fetch_pay} from '../payment/utils/PaymentRequest';
import { CalculateDays, GetLatestStartDate, newDate } from './utils/GetLicenseDates';

export default function LicenseSelectDetails(params) {
	
  const user = params.user;
  const sportRows = params.sports;
  const eventRows = params.events;
	const selectedLicense = params.selectedLicense;
  const [formValue, setFormValue] = useState({	license: selectedLicense,
		sport: null,
		event: null,
		startDate: GetLatestStartDate(user.licenser),
		endDate: adjustDate(GetLatestStartDate(user.licenser)),
		price: selectedLicense.amount,
		age: (new Date().getFullYear() - new Date(user.birth_date).getFullYear() > 20) ? "Senior" : "Junior",
		userID: user.id,
		name: user.first_name + " " + user.last_name,
		licenseAdded: false,
		user_key: user.key_id}
		);
  const [minDate, setMinDate] = useState(null);
	const pricePerDay = selectedLicense.amount;
  const [showAlert,setShowAlert] = useState((user.licenser != null && user.licenser.length > 0) ?
    user.licenser.map(element => {
      if(element.status == "ACTIVE"){
        return true
      } else {
        return false
      }
    }) : false)
	const [licenseAddedToast, setLicenseAddedToast] = useState(false)
	const [licenseErrorToast, setLicenseErrorToast] = useState(false)

	var minStartDate = new Date(GetLatestStartDate(user.licenser));
	minStartDate.setDate(minStartDate.getDate() - 1);

  function handleSubmit(e){
    e.preventDefault();
    if(e.target.checkValidity()){
      handleClickPay();
    } 
  };

	async function handleClickPay() {      
    const order = await new_order();
    const payment = {
      description: "Licensköp",
      price: formValue.price,
      orders: [{        
				reference: "O" + order.id + "U" + formValue.userID,
				name: "License_" + formValue.license.id,
				type: "PRODUCT",
				class: "License",
				description: formValue.license.text,
				vatPercent: 2500,
				amount: formValue.price,
				vatAmount: formValue.price*0.25
			}],
      metadata: {
        data: [formValue]
      },  
      
    };
    localStorage.setItem("order-key", order.msg.key_id);
    const paymentBody = CreatePaymentBody(payment,order.id);
    const obj = await fetch_pay(paymentBody);
    await update_order(order.msg.key_id,obj,paymentBody);
    const url = obj.data.operations.find(({rel}) => rel === "redirect-checkout").href;
    window.open(url, "_self");
  }

	function adjustDate(fromDate){
    var date = new Date(fromDate);
		if(selectedLicense.type == "ROLLING_PERIOD"){
			date.setMonth(date.getMonth()+Number(selectedLicense.rolling_periods));
		} 
		
    return newDate(date);
  }

  const onStartDateChange = (e) => {
		if(e !== "" && formValue.license.type == "ROLLING_FLEXIBEL"){
			const date = formatDate(e);
			date.setDate(date.getDate() - 1);
			setMinDate(new Date(date));
			if(!formValue.endDate){
				setFormValue({...formValue, 
					["startDate"]:e,
					["endDate"]: e,
					["price"]:pricePerDay*CalculateDays(e,e)})
			}
			if(formValue.endDate){
				if(formatDate(e).getTime() > formatDate(formValue.endDate).getTime()){
					setFormValue({...formValue, 
												["startDate"]:e,
												["endDate"]: e,
												["price"]:pricePerDay*CalculateDays(e,e)})
				}
				else {
					setFormValue({...formValue, 
						["price"]:pricePerDay*CalculateDays(e,formValue.endDate)})
				};
			}
		} else {
			if(selectedLicense.type == "ROLLING_FLEXIBEL"){
				setFormValue({...formValue, 
				["startDate"]:GetLatestStartDate(user.licenser),
				["endDate"]:GetLatestStartDate(user.licenser)})
			}
			if(selectedLicense.type == "FIXED"){
				setFormValue({...formValue, 
					["startDate"]:selectedLicense.fixed_start_date,
					["endDate"]:selectedLicense.fixed_end_date})
			}
			if(selectedLicense.type == "ROLLING_PERIOD"){
				const newEndDate = adjustDate(GetLatestStartDate(user.licenser));
				setFormValue({...formValue, 
				["startDate"]:GetLatestStartDate(user.licenser),
				["endDate"]:newEndDate})
			}
		}
  };

  const onEndDateChange = (e) => {
		if(e !== "" && formValue.license.type == "ROLLING_FLEXIBEL"){
			setFormValue({...formValue, 
				["endDate"]:e,
				["price"]: pricePerDay*CalculateDays(formValue.startDate,e)})
		}
  };

  const onChange = (e) => {
		setFormValue({...formValue, 
			[e.name]: {id:e.value,text:e.text}})
  };

  return (
    <>
			<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        <MDBModalDialog>
					<MDBValidation onSubmit={handleSubmit}>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Licens - {selectedLicense.text}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
							{showAlert ? <MDBAlert
								className='d-flex justify-content-between'
								defaultOpen
								autohide 
								delay={10000}
								color='warning'
								dismissBtn
								position='bottom-center'>
								<p className='mb-0'>
								Aktiv licens finns redan för {user.first_name + " " + user.last_name}.<br/>
								Startdatum justeras efter när licenserna går ut.
								</p>
							</MDBAlert> : null}
							<MDBAlert
								color='primary'
								autohide
								position='top-right'
								delay={2000}
								appendToBody
								open={licenseAddedToast}
								onClosed={params.close}
								onClose={() => setLicenseAddedToast(false)}
							>
								Licens har lagts till för {user.first_name} {user.last_name}
							</MDBAlert>
							<MDBAlert
								color='danger'
								autohide
								position='top-right'
								delay={2000}
								appendToBody
								open={licenseErrorToast}
								onClose={() => setLicenseErrorToast(false)}
							>
								Licens kunde inte läggas till för {user.first_name} {user.last_name}
							</MDBAlert>
							{/* <MDBRow>
								<MDBCol md='12'>     
									<MDBRow className='d-flex justify-content-center'>
											<MDBRow className='mb-3'>
												<MDBSelect
													className='col-md-12'
													label='Gren'
													data={sportRows}
													name="sport"
													validation
													validFeedback=''
													invalidFeedback='Ange en gren'
													onValueChange={onChange}
													preventFirstSelection
												/>
											</MDBRow>
											<MDBRow className='mb-3'>
												<MDBValidationItem className={selectedLicense.type == "ROLLING_FLEXIBEL" ? "d-block col-md-6" : "d-none"}>
													<MDBDatepicker 
														disablePast 
														clearBtnText='' 
														format='yyyy-mm-dd' 
														disabled
														className='bg-white text-dark' 
														onChange={onStartDateChange} 
														value={formValue.startDate}
														min={minStartDate}
														required
														name="startDate" 
														label='Startdatum'/>
												</MDBValidationItem>
												<MDBValidationItem className={selectedLicense.type == "ROLLING_FLEXIBEL" ? "d-block col-md-6" : "d-none"}>
													<MDBDatepicker 
														disablePast 
														clearBtnText='' 
														format='yyyy-mm-dd' 
														disabled
														className='bg-white text-dark'
														onChange={onEndDateChange} 
														min={minDate} 
														value={formValue.endDate}
														required
														name="endDate" 
														label='Slutdatum'/>
												</MDBValidationItem>
											</MDBRow>
											<MDBRow className='mb-3'>
												<MDBValidationItem className={selectedLicense.type == "ROLLING_FLEXIBEL" ? "d-block col-md-12" : "d-none"}>
													<MDBSelect
														label='Tävling'
														data={eventRows}
														name="event"
														validation={selectedLicense.type == "ROLLING_FLEXIBEL" ? true : false}
														validFeedback=''
														invalidFeedback='Ange en gren'
														onValueChange={onChange}
														preventFirstSelection
													/>
												</MDBValidationItem>
											</MDBRow>
									</MDBRow>
								</MDBCol>
							</MDBRow> */}
							<BorderCol headline="Sammanfattning" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 my-4 position-relative'>
								<h5>{selectedLicense.text}</h5>
								<MDBRow>
									<MDBCol>Giltlig fr.o.m:</MDBCol>
									<MDBCol className='text-end'><span className='text-muted'>{formValue.startDate}</span></MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol>Giltlig t.o.m:</MDBCol>
									<MDBCol className='text-end'><span className='text-muted'>{formValue.endDate}</span></MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol>Pris:</MDBCol>
									<MDBCol className='text-end'><span className='text-muted'>{formValue.price}</span></MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol>Åldersklass:</MDBCol>
									<MDBCol className='text-end'><span className='text-muted'>{formValue.age}</span></MDBCol>
								</MDBRow>
								{/* <MDBRow>
									<MDBCol>Gren:</MDBCol>
									<MDBCol className='text-end'><span className='text-muted'>{formValue.sport ? formValue.sport.text : "-"}</span></MDBCol>
								</MDBRow>
								{selectedLicense.type == "ROLLING_FLEXIBEL" ? <MDBRow>
									<MDBCol>Tävling:</MDBCol>
									<MDBCol className='text-end'><span className='text-muted'>{formValue.event ? formValue.event.text : "-"}</span></MDBCol>
								</MDBRow> : null} */}
							</BorderCol>
						</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type='button' color='secondary' onClick={params.close}>
                Stäng
              </MDBBtn>
							<MDBBtn type='submit'>
                Betala
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
					</MDBValidation>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}