import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import {    
  MDBRow, 
  MDBCol, 
  MDBContainer,
	MDBValidation,
	MDBValidationItem,
	MDBInput,
	MDBBtn,
	MDBAlert
 } from 'mdb-react-ui-kit';
 import { BorderCol } from '../modules/BorderCol';
 import { fetch_post } from '../../services/common';

export default function ResetPassword() {

	const [input, setInput] = useState(null)
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMsg, setAlertMsg] = useState("")
	const [alertColor, setAlertColor] = useState('warning')

	const submit = async (e) => {

		e.preventDefault();
		if (e.target.checkValidity()) {
			const response = await fetch_post("auth/reset-password", {"username": input});
			if (!response.ok) {
				setAlertColor('danger')
				setAlertMsg("Något gick fel, kontakta administratör.")
				setOpenAlert(true)
			}    
			if(response.status == "error"){
				setAlertColor('danger')
				setAlertMsg(response.data.error_msg)
				setOpenAlert(true)
			} else {
				setAlertColor('primary')
				setAlertMsg(response.data.msg)
				setOpenAlert(true)
			}
			
		}
	}
  
  return (
    <>
			<MDBContainer className="py-2 h-100 mt-4">
				<MDBRow>
					<MDBCol md="3"/>
					<MDBCol md="6">
						<BorderCol headline="Återställ lösenord" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
							<MDBValidation onSubmit={submit} className='row g-3'>
								<MDBValidationItem feedback='Ange e-post, IdrottID (IdrottOnline ID) eller personnummer.' invalid>
									<MDBInput
										onChange={(e) => setInput(e.target.value)}								
										label='E-post, IdrottID (IdrottOnline ID) eller personnummer'
										required
									/>
								</MDBValidationItem>
								<MDBAlert open={openAlert} className='mt-3 w-100' color={alertColor}>
									{alertMsg}
								</MDBAlert>
								<div className='col-12 mt-3 text-center'>
									<MDBBtn type='submit'>Återställ lösenord</MDBBtn>
								</div>
							</MDBValidation>
						</BorderCol>
					</MDBCol>
					<MDBCol md="3"/>
				</MDBRow>
			</MDBContainer>
    </>
  );
}