import { fetch_post } from "../../../../services/common";

export async function EditSportgren(id, name){
	
	const response = await fetch_post("app/sportgren/do_submit/" + id, {
    action: "edit_sportgren",
    db_name: name,
	})
		
	return  response;
}